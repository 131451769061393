<template>
    <div>
        <nav-bar></nav-bar>
        <div>
          <img src="../assets/images/lxwm.png" alt="" class="img">
        </div>
        <div class="text-center">
            <div>
                <img src="../assets/images/service-support/phone.png" class="service-support-us-phone">
                <div class="service-support-us-left-hotline">热线服务</div>
                <div  class="service-support-us-left-phone">热线电话：<span class="green">010-81333426</span></div>
                <div class="service-support-us-left-time"> 周一至周五 9:00-12:00 13:00-18:00(北京时间）  </div>
                <div class="answer">
                    公司提供7×24小时电话服务，方便用户咨询产品、技术、业务、服务等问题，用户可随时拨通热线，方便您对产品、技术、 业务需求、服务等选购，使用、维护过程中遇到的各类问题。 
                </div>
            </div>
          </div>
          <div class="remark">你也可以<span class="green">发送邮件</span>与我们联系。如有紧急需求，建议优先通过<span class="green">热线电话</span>获取更实时的服务。</div>
          <Footer></Footer>
          </div>
</template>
<script>
// @ is an alias to /src
　import { reactive, onMounted,toRefs ,ref,nextTick, computed } from 'vue'
import navBar from '@/components/NavBar'
import Footer from '@/components/Footer'
import { useRouter } from 'vue-router';
export default {
    name: 'Home',
    components: {
        navBar,
        Footer,

    },
}
</script>
<style scoped>
.service-support-header{
  font-size: 40px;padding: 140px 0px 80px;text-align: center;
}
.service-support-us-left-hotline{
  font-size:30px ;padding-bottom:30px ;text-align:center ;
}
.service-support-us-left-phone{
  font-size: 24px;color: #666666;text-align: center;padding-bottom: 30px;
}
.service-support-us-left-time{
  color: #666666;text-align: center;font-size: 20px;
}
.service-support-us-phone{
  display: block;width: 66px;margin: 76px auto 30px;
  /* margin: 0 auto; */
}
.remark{
  text-align:center ;padding:0px 0px 60px;
  font-size: 20px;
}
.answer{
    font-size:20px ;color: #666666;width: 1294px;margin: 50px auto 50px;line-height: 40px;
}
</style>